.header {
    background-color: #3B6058;
    color: white;
    padding: 1em;
    height: 40px;
    display: flex;
    padding: 20px 80px;
  }

@media (max-width: 600px) {
  .header {
    padding: 20px;
    justify-content: center;
  }
}