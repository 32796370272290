/* MAIN SECTION */

.main-section {
    background-image: url('../../assets/main-section.webp');
    background-size: cover;
    background-position: center;
    color: white;
    height: 400px;
    display: flex;
  }
  
  .main-section-content {
    max-width: 33%;
    background-color: rgba(255,255,255,0.85);
    color: #000;
    height: 100%;
    text-align: left;
    padding: 0px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
  
  .main-section p {
    margin:0px;
    font-size: 1.125em;
    line-height: 1.25em;
  }
  
  /* -- mobile -- */
  
  @media (max-width: 600px) {
    .main-section {
      height: auto;
    }
    .main-section-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 20px;
      margin: 80px 0;
      max-width: 100%;
      
    }
  
  }
  
  /* DETAILS SECTION */
  
  .details-section {
    padding: 40px 80px 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 46px;
  
  }
  
  .details-section p {
    max-width: 650px; 
    font-size: 1.125em;
  
  }
  
  .details {
    display: flex;
    justify-content: space-around;
    flex-direction: row;
    gap: 54px;
  }
  
  .detail {
    margin: 1.125em;
    text-align: center;
  }
  
  .details-section h3 {
    margin-bottom: 0px;
  }
  
  .detail p {
    margin-top: 8px;
  }
  
  
  .step {
    display: block;
    width: 66px;
    height: 66px; 
    background-color: #B1BFBC;
    border-radius: 50px;
    color: #3B6058;
    font-size: 36px;
    line-height: 64px;
    font-weight: 500;
    margin: 10px auto;
  }
  
    /* -- mobile -- */

  @media (max-width: 600px) {
    .details-section {
      padding: 50px 20px 80px;
    }
  
    .details {
      flex-direction: column;
      gap: 28px;
    }
  }
  
  /* IMAGE OVERLAY */
  
  .image-overlay-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #3B6058;
    color: white;
    position: relative;
    overflow: visible;
    height: 400px;
    margin: 50px 0;
  }
  
  .text-content {
    max-width: 40%;
    z-index: 2;
    background-color: rgba(255,255,255,0.85);
    color: #000;
    text-align: left;
    padding: 0 40px 0 80px;
    height: 100%;
    align-content: center;
  }
  
  .text-content h2 {
    margin-bottom: 20px;
  }
  
  .text-content p {
    font-size: 1.125em;
    line-height: 1.5em;
  }
  
  .images-container {
    position: relative;
    width: 50%;
    height: 100%; 
    display: flex;
  }
  
  .image {
    width: 100%;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
    position: absolute;
  }
  
  .image1 {
    left: -3%;
    z-index: 1;
    width: 75%;
    align-self: center;
  
  }
  
  .image2 {
    top: -15%;
    left: 60%;
    z-index: 2;
    width: 40%;
  }
  
  .image3 {
    top: 50%;
    left: 45%;
    z-index: 3;
    width: 50%;
  }
  
  .image4 {
    left: 5%;
    z-index: 1;
    width: 75%;
    align-self: center;
  }
  
  .image5 {
    top: -15%;
    left: 50%;
    z-index: 2;
    width: 40%;
  }
  
  .image6 {
    top: 40%;
    left: 45%;
    z-index: 3;
    width: 55%;
    border-radius: 8px 0px 0px 8px;
  }
  
  .image:hover {
    transform: scale(1.05);
  }
  
  /* -- mobile -- */
  
  @media (max-width: 768px) {
    .image-overlay-section {
      flex-direction: column;
      align-items: center;
      padding: 0;
      height: fit-content;
    }
  
    .reverse {
      flex-direction: column-reverse;
      margin-bottom: 0px;
    }
  
    .images-container {
      height: 300px;
      width: 100%;
    }
  
    .text-content {
      max-width: 100%;
      padding: 60px 20px;
      margin: 0;
      text-align: center;
    }
  
    .images {
      max-width: 100%;
      grid-template-columns: repeat(2, 1fr);
    }
  
    .image2 {
      top: -15%;
      left: 55%;
      z-index: 2;
      width: 40%;
    }
    .image3 {
      top: 55%;
      left: 45%;
      z-index: 3;
      width: 55%;
    }
    .image4 {
        left: 0%;
      border-radius: 0px;
    }
  
    .image6 {
      top: 55%;
    }
    
  }
  
  @media (max-width: 480px) {
    .images {
      grid-template-columns: 1fr;
    }
  }