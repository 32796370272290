@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: #ecf0f1;
}

.App {
  text-align: center;
}

body {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
}

/* TYPOGRAPHY */

h1, h2, h3, h4 {
  font-family: 'Poppins', sans-serif;
  margin: 0px;
}

h1 {
  font-weight: 700;
  line-height: 1.3em;
  font-size: 2.125em;
  margin:0px;
}

h2 {
  font-size: 1.75em;
  line-height: 1.25em;
  font-weight: 600;
}

h3 {
  font-weight: 600;
}

p {
  margin: 0px;
}


/* -- mobile -- */

@media (max-width: 600px) {

  h1 {
    font-size: 1.5em;
  }
  h2 {
    font-size: 1.35em;
    line-height: 1.5em;
  }
}


/* BUTTONS */

button {
  transition: transform 0.3s ease-in-out;
}

button:hover {
    transform: scale(1.05);
}

button:active {
  background-color: #e1b254;
}

.btn-primary {
  background-color: #FFCB62;
  border: none;
  border-radius: 8px;
  padding: 16px 20px;
  color: #463303;
  font-weight: 700;
  font-size: 1.125em;
  cursor: pointer;
  width: auto;
}

